import React from "react";
import { LayoutProps } from './types';
import { Header, HeaderProps } from "./header";
import { Drawer, DrawerProps } from "./drawer";
import { Footer } from "./footer";
import { useDrawerContext } from './drawerProvider';
import { Container, Content, ContentWithFooter } from "./styles";
import { BOTTOM_ACTIONS, HEADER_ACTIONS, TOP_ACTIONS } from "./constants";
import { useNavigate } from "react-router-dom";
import { pageUrls } from "../../pages/urls";

export const Layout: React.FC<LayoutProps> = ({
  children,
}) => {
  const { isOpen, toggleIsOpen } = useDrawerContext();
  const navigate = useNavigate();

  const dynamicActionProps = {
    navigate,
  };

  const topActions = TOP_ACTIONS(dynamicActionProps);
  const bottomActions = BOTTOM_ACTIONS(dynamicActionProps);
  const headerActions = HEADER_ACTIONS(dynamicActionProps);

  const showDrawerIcon = !![...topActions, ...bottomActions].length;

  const headerProps: HeaderProps = {
    desktopOnlyActions: HEADER_ACTIONS(dynamicActionProps),
    onLogoClick: () => navigate(pageUrls.landingPage()),
    onNavigationClick: () => {
      toggleIsOpen();
    },
    showDrawerIcon,
  };

  const onClose = () => toggleIsOpen();

  const drawerProps: DrawerProps = {
    bottomActions: bottomActions,
    topActions: topActions,
    mobileOnlyActions: headerActions,
    onClose,
    isOpen,
  };

  return (
    <Container>
      <ContentWithFooter>
        <Content>
          {children}
        </Content>
        <Footer />
      </ContentWithFooter>
      <Drawer {...drawerProps} />
      <Header {...headerProps} />
    </Container>
  );
};
