import { Paragraph } from "./paragraph";
import { Image } from "./image";
import { Title } from "./title";
import { ARTICLES_COMPONENTS_TYPES } from "../types";

const ARTICLE_COMPONENT_MAP: Record<
  // ARTICLES_COMPONENTS_TYPES,
  string,
  React.FC<any>
> = {
  [ARTICLES_COMPONENTS_TYPES.title]: Title,
  [ARTICLES_COMPONENTS_TYPES.text]: Paragraph,
  [ARTICLES_COMPONENTS_TYPES.image]: Image,
};

const getArticleComponentByType = (type: ARTICLES_COMPONENTS_TYPES) => {
  return ARTICLE_COMPONENT_MAP[type] || null;
};

export { Paragraph, Image, Title, getArticleComponentByType };
