import React from 'react';
import { v4 as uuid } from 'uuid';
import { getLocalStorageItem, LOCAL_STORAGE_KEYS, setLocalStorageItem } from '../../utils/localStorage';

export const SessionContext = React.createContext<string | null>(null);

const getOrCreateSessionId = () => {
  let sessionId = getLocalStorageItem(LOCAL_STORAGE_KEYS.SESSION_ID);

  if (!sessionId) {
    sessionId = uuid();
    setLocalStorageItem(LOCAL_STORAGE_KEYS.SESSION_ID, sessionId);
  }

  return sessionId;
};

export const SessionProvider: React.FC<{ children: any }> = ({ children }) => {
  const sessionId = getOrCreateSessionId();

  return <SessionContext.Provider value={sessionId}>{children}</SessionContext.Provider>;
};
