import React from "react";
import { DrawerProps } from "./types";
import { Button, Colors, Variants, Size } from "../../button";
import {
  BottomContent,
  Container, DesktopContent, MobileOnlyContent, TopContent
} from "./styles";

export const Drawer: React.FC<DrawerProps> = ({ mobileOnlyActions = [], topActions = [], bottomActions = [], onClose, isOpen = false }) => {
  const commonButtonProps = {
    color: Colors.text,
    variant: Variants.text,
    fullWidth: true,
    size: Size.medium,
  };

  return (
    <Container isOpen={isOpen}>
      <MobileOnlyContent>
        {mobileOnlyActions.map(({ type, text, onClick }) => type === 'button' && (
          <Button {...commonButtonProps} text={text} onClick={onClick as VoidFunction} />
        ))}
      </MobileOnlyContent>
      <DesktopContent>
        <TopContent>
          {topActions.map(({ type, text, onClick }) => type === 'button' && (
            <Button {...commonButtonProps} text={text} onClick={onClick as VoidFunction} />
          ))}
        </TopContent>
        <BottomContent>
          {bottomActions.map(({ type, text, onClick }) => type === 'button' && (
            <Button {...commonButtonProps} text={text} onClick={onClick as VoidFunction} />
          ))}
        </BottomContent>
      </DesktopContent>
    </Container>
  );
};
