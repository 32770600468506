import { useOnSuccess } from "./useOnSuccess";
export var useApiProviderSuccessLogic = function (_a) {
    var updateUserPlan = _a.updateUserPlan, addUserPlan = _a.addUserPlan, updatePlaceUserPlans = _a.updatePlaceUserPlans, loginUser = _a.loginUser, getUserPlans = _a.getUserPlans;
    useOnSuccess({ queryPending: updateUserPlan, queryOnSuccess: getUserPlans });
    useOnSuccess({ queryPending: addUserPlan, queryOnSuccess: getUserPlans });
    useOnSuccess({
        queryPending: updatePlaceUserPlans,
        queryOnSuccess: getUserPlans,
    });
    useOnSuccess({ queryPending: loginUser, queryOnSuccess: getUserPlans });
};
