var translations = {
    "main.landingPage.newsletter.header": "Bądź na bieżąco",
    "main.landingPage.newsletter.h2": "Powiadomimy cię kiedy rozszerzymy swoje granice",
    "main.landingPage.newsletter.input": "Wprowadź email",
    "main.landingPage.newsletter.inputLabel": "E-mail",
    "main.landingPage.newsletter.button": "Subskrybuj",
    "main.landingPage.features.header": "Funkcjonalności",
    "main.landingPage.features.feature1Header": "Spersonalizowany przewodnik",
    "main.landingPage.features.feature1Description": "Dzięki Wizytor każda podróż jest dostosowana do Twoich preferencji. Niezależnie od tego, czy masz mało czasu, czy ponownie odwiedzasz znane miasto, nasza aplikacja tworzy spersonalizowane przewodniki na podstawie Twojej lokalizacji, dostępnego czasu i nie tylko. Ciesz się starannie dobranym doświadczeniem, które odpowiada Twoim zainteresowaniom bez żadnego wysiłku z twojej strony.",
    "main.landingPage.features.feature2Header": "Wszystko w jednym miejscu",
    "main.landingPage.features.feature2Description": "Masz dość przeszukiwania niekończących się wpisów na blogu w poszukiwaniu informacji o podróżach? Wizytor konsoliduje wszystko, czego potrzebujesz, w jednym wygodnym miejscu. Od szczegółowych spostrzeżeń po wskazówki, nasza aplikacja zapewnia kompleksowy dostęp do wszystkich informacji potrzebnych do niezapomnianej podróży.",
    "main.landingPage.features.feature3Header": "Wygodne plany podróży",
    "main.landingPage.features.feature3Description": "Pożegnaj się z problemami z planowaniem wycieczki na ostatnią chwilę dzięki Wizytor. Przygotuj się na kolejną podróż z wyprzedzeniem i zaoszczędź cenny czas na miejscu. Nasza aplikacja umożliwia bezproblemowe tworzenie i zapisywanie planów podróży, zapewniając bezstresową podróż od początku do końca",
    "main.landingPage.features.feature4Header": "Brak opinii, tylko fakty",
    "main.landingPage.features.feature4Description": "W Wizytor stawiamy na dokładność i niezawodność. Opieramy się wyłącznie na zweryfikowanych faktach z uznanych źródeł. Możesz być pewien, że informacje, które otrzymasz, opierają się na autentyczności, a nie subiektywnych opiniach lub recenzjach.",
    "main.landingPage.numbers.header": "Wizytor w liczbach",
    "main.landingPage.numbers.paragraph": "rozwijamy się każdego dnia",
    "main.landingPage.numbers.cityNumber": "500+",
    "main.landingPage.numbers.cityTitle": "miast",
    "main.landingPage.numbers.countryNumber": "1",
    "main.landingPage.numbers.countryTitle": "państwo",
    "main.landingPage.numbers.placesNumber": "55k+",
    "main.landingPage.numbers.placesTitle": "miejsc",
    "main.landingPage.main.header": "Spersonalizowany przewodnik turystyczny w Twojej kieszeni",
    "main.landingPage.main.description": "Odkryj świat wokół siebie jak nigdy dotąd z Wizytor. Pożegnaj się z generycznymi przewodnikami turystycznymi i przygotuj się na spersonalizowane doświadczenie eksploracyjne dostosowane do Ciebie",
    "main.forgotPassword.title": "Przypomnij hasło",
    "main.forgotPassword.successfulMessage": "Wiadomość została wysłana. Sprawdź maila",
    "main.forgotPassword.loginRedirectButton": "Zaloguj się",
    "main.forgotPassword.loginRedirectText": "Przejdź do strony logowania",
    "main.forgotPassword.submit": "Wyślij",
    "main.forgotPassword.loader": "Wysyłamy maila, proszę czekac...",
    "main.resetPassword.title": "Zresetuj hasło",
    "main.resetPassword.submit": "Resetuj hasło",
    "main.resetPassword.loaderUpdatingPassword": "Aktualizujemy twoje hasło...",
    "main.registerAccount.successfulMessage": "Konto utworzone, sprawdź skrzynkę pocztową, aby zweryfikować konto. Po udanej weryfikacji możesz zalogować się do aplikacji",
    "main.registerAccount.title": "Stwórz konto",
    "main.registerAccount.submit": "Zarejestruj",
    "main.registerAccount.loaderCreatingUser": "Tworzenie konta...",
    "main.registerAccount.loginRedirectText": "Posiadasz konto?",
    "main.registerAccount.loginRedirectButton": "Zaloguj",
    "main.registerAccount.passwordRequirements": "Hasło musi posiadać: \n8 znaków \n1 dużą literę \n1 cyfrę \n1 znak specjalny",
    "main.registerAccount.confirmPolicyTermsAndCondition": "Akceptuję Regulamin i Warunki użytkowania aplikacji",
    "main.login.title": "Zaloguj",
    "main.login.submit": "Zaloguj",
    "main.login.registerRedirectText": "Nie posiadasz konta?",
    "main.login.registerRedirectButton": "Stwórz konto",
    "main.login.registerForgotPassword": "Zresetuj hasło",
    "main.verifyAccount.title": "Weryfikacja konta",
    "main.verifyAccount.waiting": "Weryfikacja trwa, proszę czekać...",
    "main.verifyAccount.error": "Coś poszło nie tak",
    "main.verifyAccount.success": "Twoje konto zostało zweryfikowane",
    "main.verifyAccountModal.title": "Weryfikuj konto",
    "main.verifyAccountModal.message": "Wyślij wiadomość z potwierdzeniem na maila",
    "main.verifyAccountModal.sendVerificationButton": "Wyślij",
    "main.verifyAccountModal.backButton": "Cofnij",
    "main.userProfile.title": "Ustawienia konta",
    "main.userPlans.header": "Twoje plany",
    "main.userPlans.availableTime": "{{hours}}g {{minutes}}min",
    "main.userPlans.availableDays": "{{days}} dni",
    "main.userPlans.placesNumber": "{{placesNum}} miejsc",
    "main.userPlans.removeAccountConfirmHeader": "Usuń konto",
    "main.places.header": "Miejsca",
    "main.places.showAll": "Zobacz wszystkie",
    "main.plan.loadingPlan": "Ładowanie planu...",
    "main.plan.calculatingRoutes": "Tworzenie planu...",
    "main.plan.loadingPlace": "Ładowanie miejsca...",
    "main.plan.placesListHeader": "Dzień {{day}}",
    "main.main.nearbyPlacesHeader": "Miejsca blisko ciebie",
    "main.main.popularPlacesHeader": "Popularne miejsca w {{city}}",
    "main.placeDetails.scoreOverallHeader": "Ranking ogólny",
    "main.placeDetails.scoreOverallDescription": "",
    "main.placeDetails.dataHeader": "Ranking danych",
    "main.placeDetails.dataDescription": "",
    "main.placeDetails.childrenPlacesHeader": "Znajdziesz tutaj:",
    "main.placeDetails.parentPlacesHeader": "To miejsce jest częścią:",
    "main.placeDetails.factsHeader": "Ciekawostki",
    "main.articles.estimatedReadingTime": "Czas {{estimatedReadingTime}} minut czytania",
    "main.articles.placeRedirect": "Sprawdź",
    "main.articles.planRedirect": "Sprawdź",
    "main.input.label.email": "Email",
    "main.input.label.password": "Hasło",
    "main.input.label.confirmPassword": "Powtórz hasło",
    "main.input.label.firstName": "Imię",
    "main.input.label.lastName": "Nazwisko",
    "main.input.label.availableTime": "Dostępny czas",
    "main.input.label.availableDays": "Liczba dni",
    "main.input.label.availableTimePerDay": "Dzień {{day}}",
    "main.input.label.availableTimeSpecificSwitch": "Zmień czas dla każdego dnia",
    "main.input.label.startPlace": "Punkt startowy",
    "main.input.label.finishPlace": "Punkt końcowy",
    "main.input.label.sameStartFinishPlace": "Wspólny punkt startowy i końcowy",
    "main.input.label.specifiedPlacePerDay": "Zmień miejsca dla każdego dnia",
    "main.input.label.oldPassword": "Stare hasło",
    "main.input.label.newPassword": "Nowe hasło",
    "main.input.label.search": "Wyszukaj miejsca",
    "main.button.showMore": "Pokaż więcej",
    "main.button.logout": "Logout",
    "main.button.changePassword": "Zmień hasło",
    "main.button.removeAccount": "Usuń konto",
    "main.button.goBackHome": "Wróć do strony głównej",
    "main.button.goBackPlaceList": "Wróć do listy miejsc",
    "main.button.goBackUserPlanList": "Wróć do listy planów",
    "main.button.goBackPlan": "Wróć do planu",
    "main.button.renamePlan": "Zmień nazwę",
    "main.button.removePlan": "Usuń plan",
    "main.button.readMore": "Czytaj więcej",
    "main.button.readLess": "Zwiń",
    "main.button.showOnMap": "Pokaż na mapie",
    "main.button.refresh": "Odśwież",
    "main.button.planCreate": "Stwórz plan",
    "main.button.showAdvancedFilters": "Zaawansowane filtry",
    "main.button.planEdit": "Aktualizuj plan",
    "main.button.verifyAccount": "Weryfikuj konto",
    "main.button.removeAccountCancel": "Nie usuwaj",
    "main.button.removeAccountConfirm": "Usuń",
    "main.button.showAll": "Zobacz wszystkie",
    "main.button.back": "Cofnij",
    "main.button.next": "Dalej",
    "main.tag.tourism": "Punkt Turystyczny",
    "main.tag.architecture": "Architektura",
    "main.tag.park": "Park",
    "main.tag.historic": "Historia",
    "main.tag.facility": "Miejsce użytkowe",
    "main.tag.education": "Edukacja",
    "main.tag.commercial": "Miejsce komercyjne",
    "main.tag.industrial": "Przemysł",
    "main.tag.neighborhood": "Dzielnica",
    "main.tag.city": "Miasto",
    "main.tag.natural": "Natura",
    "main.tag.square": "Plac",
    "main.tag.street": "Ulica",
    "main.tag.station": "Stacja",
    "main.tag.cinema": "Kino",
    "main.tag.theatre": "Teatr",
    "main.tag.library": "Biblioteka",
    "main.tag.bridge": "Most",
    "main.error.userExists": "Użytkownik z tym emailem już istnieje",
    "main.error.emailFormat": "Niepoprawny email",
    "main.error.passwordRequirements": "Hasło nie spełnia wymagań",
    "main.error.passwordNotMatch": "Podane różne hasła",
    "main.error.missingEmail": "Nie podano email",
    "main.error.missingFirstName": "Nie podano imienia",
    "main.error.missingLastName": "Nie podano nazwiska",
    "main.error.missingPassword": "Nie podano hasła",
    "main.error.cannotLogin": "Nie można zalogować, spróbuj ponownie",
    "main.error.cannotCreateUser": "Nie można stworzyć konta, spróbuj ponownie",
    "main.error.cannotUpdatePassword": "Nie można zaktualizować hasła, spróbuj ponownie",
    "main.drawer.button.main": "Strona główna",
    "main.drawer.button.map": "Mapa",
    "main.drawer.button.userPlans": "Twoje plany",
    "main.drawer.button.userProfile": "Profil",
    "main.drawer.button.articles": "Artykuły",
    "main.drawer.language.header": "Wybierz język",
    "main.button.close": "Zamknij",
};
export default translations;
