import React from "react";
import { FooterProps } from "./types";
import {
  Container,
} from "./styles";

export const Footer: React.FC<FooterProps> = () => {
  return (
    <Container>
      Wizytor | 2024
    </Container>
  );
};
