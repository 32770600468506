export var initialDataValues = {
    searchPlaces: [],
    nearbyPlaces: [],
    popularCityPlaces: {},
    mapPlaces: [],
    selectedPlace: {},
    userPlans: [],
    locationSuggestions: [],
    userLocation: {},
    selectedUserPlan: {},
    placesList: [],
    articles: [],
    article: {},
};
