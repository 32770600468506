import React from "react";
import { ParagraphProps } from "./types";
import { ParagraphContainer, ParagraphText } from "./styles";

export const Paragraph = ({ text }: ParagraphProps) => {
  return (
    <ParagraphContainer>
      <ParagraphText>{text}</ParagraphText>
    </ParagraphContainer>
  );
};
