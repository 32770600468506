import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
var useCommonResponse = function (_a) {
    var response = _a.response, queryField = _a.queryField, onError = _a.onError, onSuccess = _a.onSuccess, onLoading = _a.onLoading, onClean = _a.onClean;
    var _b = useState(null), data = _b[0], setData = _b[1];
    var cleanData = function () {
        setData(null);
        onClean === null || onClean === void 0 ? void 0 : onClean();
    };
    useEffect(function () {
        var _a;
        if (response.called && response.loading) {
            onLoading === null || onLoading === void 0 ? void 0 : onLoading();
        }
        else {
            var data_1 = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a[queryField];
            setData(data_1);
            if ((data_1 === null || data_1 === void 0 ? void 0 : data_1.statusCode) === 200) {
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(data_1);
            }
            else {
                onError === null || onError === void 0 ? void 0 : onError(data_1);
            }
        }
    }, [response.data, response.called, response.loading]);
    return { data: data, cleanData: cleanData };
};
export var useApiQuery = function (_a) {
    var query = _a.query, queryField = _a.queryField, onSuccess = _a.onSuccess, onLoading = _a.onLoading, onError = _a.onError, onClean = _a.onClean;
    var _b = useLazyQuery(query), request = _b[0], response = _b[1];
    var _c = useCommonResponse({
        response: response,
        queryField: queryField,
        onError: onError,
        onSuccess: onSuccess,
        onLoading: onLoading,
        onClean: onClean,
    }), data = _c.data, cleanData = _c.cleanData;
    var isFinished = response.called && !response.loading && !!data;
    return { request: request, response: response, data: data, cleanData: cleanData, isFinished: isFinished };
};
export var useApiMutation = function (_a) {
    var query = _a.query, queryField = _a.queryField, onSuccess = _a.onSuccess, onLoading = _a.onLoading, onError = _a.onError, onClean = _a.onClean;
    var _b = useMutation(query), request = _b[0], response = _b[1];
    var _c = useCommonResponse({
        response: response,
        queryField: queryField,
        onError: onError,
        onSuccess: onSuccess,
        onLoading: onLoading,
        onClean: onClean,
    }), data = _c.data, cleanData = _c.cleanData;
    var isFinished = response.called && !response.loading && !!data;
    return { request: request, response: response, data: data, cleanData: cleanData, isFinished: isFinished };
};
