export enum LOCAL_STORAGE_KEYS {
  AUTH_TOKEN = 'auth_token',
  REFRESH_TOKEN = 'refresh_token',
  USER_DATA = 'user_data',
  SESSION_ID = 'session_id',
  LANGUAGE = 'language',
}

export const getLocalStorageItem = (key: LOCAL_STORAGE_KEYS) => localStorage.getItem(key);

export const setLocalStorageItem = (key: LOCAL_STORAGE_KEYS, value: string) => localStorage.setItem(key, value);

export const removeLocalStorageItem = (key: LOCAL_STORAGE_KEYS) => localStorage.removeItem(key);
