import React from "react";
import {
  Container,
} from "./styles";
import { ArticleListProps } from "./types";
import { ArticleListItem } from "./articleListItem";

export const ArticleList = ({ articles = [] }: ArticleListProps) => {
  return (
    <Container>
      {articles.map((article) => (
        <ArticleListItem {...article} />
      ))}
    </Container>
  );
};
