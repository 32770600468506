var _a;
import { ADD_FACT_MUTATION, ADD_PLACE_IMAGE_INIT_MUTATION, ADD_USER_PLAN_MUTATION, CREATE_USER_MUTATION, FORGOT_PASSWORD_MUTATION, GET_MAP_PLACES_QUERY, GET_NEARBY_PLACES_QUERY, GET_SELECTED_PLACE_QUERY, GET_SELECTED_USER_PLAN_QUERY, GET_USER_PLANS_QUERY, LOGIN_USER_MUTATION, LOGOUT_USER_MUTATION, REMOVE_USER_MUTATION, RESET_PASSWORD_MUTATION, UPDATE_PASSWORD_MUTATION, UPDATE_PLACE_USER_PLANS_MUTATION, UPDATE_USER_MUTATION, UPDATE_USER_PLAN_MUTATION, VERIFY_USER_MUTATION, GET_LOCATION_SUGGESTIONS_QUERY, GET_LOCATION_QUERY, REMOVE_USER_PLAN_MUTATION, SUBSCRIBE_NEWSLETTER_MUTATION, AUTH_USER_MUTATION, SEND_VERIFICATION_USER_MUTATION, GET_CITY_POPULAR_PLACES_QUERY, GET_SEARCH_PLACES_QUERY, GET_PLACE_BY_IDS_QUERY, GET_ARTICLE_QUERY, GET_ARTICLES_QUERY, } from "../queries";
import { QueriesNames } from "./queriesNames";
export var queryMap = (_a = {},
    _a[QueriesNames.sendVerificationUser] = SEND_VERIFICATION_USER_MUTATION,
    _a[QueriesNames.authUser] = AUTH_USER_MUTATION,
    _a[QueriesNames.mapPlaces] = GET_MAP_PLACES_QUERY,
    _a[QueriesNames.searchPlaces] = GET_SEARCH_PLACES_QUERY,
    _a[QueriesNames.nearbyPlaces] = GET_NEARBY_PLACES_QUERY,
    _a[QueriesNames.placesByIds] = GET_PLACE_BY_IDS_QUERY,
    _a[QueriesNames.popularCityPlaces] = GET_CITY_POPULAR_PLACES_QUERY,
    _a[QueriesNames.selectedPlace] = GET_SELECTED_PLACE_QUERY,
    _a[QueriesNames.userPlans] = GET_USER_PLANS_QUERY,
    _a[QueriesNames.selectedUserPlan] = GET_SELECTED_USER_PLAN_QUERY,
    _a[QueriesNames.updateUserPlan] = UPDATE_USER_PLAN_MUTATION,
    _a[QueriesNames.removeUserPlan] = REMOVE_USER_PLAN_MUTATION,
    _a[QueriesNames.updatePlaceUserPlans] = UPDATE_PLACE_USER_PLANS_MUTATION,
    _a[QueriesNames.addPlaceImageInit] = ADD_PLACE_IMAGE_INIT_MUTATION,
    _a[QueriesNames.addFact] = ADD_FACT_MUTATION,
    _a[QueriesNames.addUserPlan] = ADD_USER_PLAN_MUTATION,
    _a[QueriesNames.loginUser] = LOGIN_USER_MUTATION,
    _a[QueriesNames.resetPassword] = RESET_PASSWORD_MUTATION,
    _a[QueriesNames.forgotPassword] = FORGOT_PASSWORD_MUTATION,
    _a[QueriesNames.verifyUser] = VERIFY_USER_MUTATION,
    _a[QueriesNames.createUser] = CREATE_USER_MUTATION,
    _a[QueriesNames.updateUser] = UPDATE_USER_MUTATION,
    _a[QueriesNames.logoutUser] = LOGOUT_USER_MUTATION,
    _a[QueriesNames.removeUser] = REMOVE_USER_MUTATION,
    _a[QueriesNames.updatePassword] = UPDATE_PASSWORD_MUTATION,
    _a[QueriesNames.location] = GET_LOCATION_QUERY,
    _a[QueriesNames.locationSuggestions] = GET_LOCATION_SUGGESTIONS_QUERY,
    _a[QueriesNames.subscribeNewsletter] = SUBSCRIBE_NEWSLETTER_MUTATION,
    _a[QueriesNames.articles] = GET_ARTICLES_QUERY,
    _a[QueriesNames.article] = GET_ARTICLE_QUERY,
    _a);
