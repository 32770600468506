import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ApiRequestContext } from "@tour-buddy/ui-api-provider";
import { Container, Content, Header, StyledAlertCircleIcon, StyledCheckCircleIcon } from "./styles";
import { pageUrls } from "../../urls";
import { Button, Variants } from "../../../components/button";

export const VerifyAccount = () => {
  const navigate = useNavigate();
  const [isSuccess, setIsSuccess] = useState(false);
  const [searchParams] = useSearchParams();
  const { verifyUser } = useContext(ApiRequestContext);
  const token = searchParams.get('token');

  useEffect(() => {
    if (token) {
      verifyUser.request({
        variables: {
          token,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (verifyUser.isFinished) {
      if (verifyUser.data?.statusCode === 200) {
        setIsSuccess(true);
      }
    }
  }, [verifyUser.data?.statusCode, verifyUser.isFinished]);

  const handleLoginClick = () => {
    // navigate(`${pageUrls.main()}?login`);
    navigate(pageUrls.landingPage());
  };

  const handleGoBackClick = () => {
    navigate(pageUrls.landingPage());
  };

  return (
    <Container>
      <Content>
        {verifyUser.response?.called && verifyUser.response?.loading ? (
          <>
            <Header>Verify Account</Header>
            <h1>Verifying, please wait...</h1>
          </>
        ) : isSuccess ? (
          <>
            <StyledCheckCircleIcon />
            <Header>Verify Account</Header>
            <div>Your account was successfully verified. Now you can login</div>
            <Button variant={Variants.contained} onClick={handleLoginClick} text="Login" />
          </>
        ) : (
          <>
            <StyledAlertCircleIcon />
            <Header>Verify Account</Header>
            <div>Something went wrong during verification of your account</div>
            <Button variant={Variants.contained} onClick={handleGoBackClick} text="Go back" />
          </>
        )}
      </Content>
    </Container>
  );
};
