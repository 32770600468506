import React, { useState } from 'react';
import { Navigate, Route, Routes } from "react-router-dom";

import { BlogHomepage } from './blog/homepage';
import { Article } from './blog/article';
import { LandingPage } from './app/landingPage';
import { PrivacyPolicy } from './user/privacyPolicy';
import { RemoveAccount } from './user/removeAccount';
import { TermsAndConditions } from './user/termsAndConditions';
import { VerifyAccount } from './user/verifyAccount';

import { pageUrls } from './urls';
import { Layout } from '../components/layout';

const defaultLayoutConfig = {
  showSearch: false,
  showDrawer: false,
};

interface SetLayoutConfigProps {
  showSearch?: boolean;
  showDrawer?: boolean;
}
export const Pages = () => {
  // const [showSearch, setShowSearch] = useState(defaultLayoutConfig.showSearch);
  // const [showDrawer, setShowDrawer] = useState(defaultLayoutConfig.showDrawer);
  const [, setShowSearch] = useState(defaultLayoutConfig.showSearch);
  const [, setShowDrawer] = useState(defaultLayoutConfig.showDrawer);

  const setLayoutConfig = (props?: SetLayoutConfigProps) => {
    setShowSearch(props?.showSearch ?? defaultLayoutConfig.showSearch);
    setShowDrawer(props?.showDrawer ?? defaultLayoutConfig.showDrawer);
    return null;
  };

  return (
    <Layout>
      <Routes>
        <Route
          path={pageUrls.landingPage()}
          element={<LandingPage />}
          loader={() => {
            setLayoutConfig();
            return {};
          }}
        />
        <Route
          path={pageUrls.homepage()}
          element={<BlogHomepage />}
          loader={() => {
            setLayoutConfig();
            return {};
          }}
        />
        <Route
          path={pageUrls.article()}
          element={<Article />}
          loader={() => {
            setLayoutConfig();
            return {};
          }}
        />
        <Route
          path={pageUrls.privacyPolicy()}
          element={<PrivacyPolicy />}
          loader={() => {
            setLayoutConfig({ showDrawer: false });
            return {};
          }}
        />
        <Route
          path={pageUrls.termsAndConditions()}
          element={<TermsAndConditions />}
          loader={() => {
            setLayoutConfig({ showDrawer: false });
            return {};
          }}
        />
        <Route
          path={pageUrls.removeAccount()}
          element={<RemoveAccount />}
          loader={() => {
            setLayoutConfig({ showDrawer: false });
            return {};
          }}
        />
        <Route
          path={pageUrls.verifyAccount()}
          element={<VerifyAccount />}
          loader={() => {
            setLayoutConfig({ showDrawer: false });
            return {};
          }}
        />
        <Route
          path="*"
         element={(
            <Navigate to={pageUrls.homepage()} replace={true} />
          )}
        />
      </Routes>
    </Layout>
  );
};
