export const pageUrls = {
  homepage: () => '/blog',
  article: (language = ':language', articlePath = ':path') => `/article/${language}/${articlePath}`,
  landingPage: () => '/landing',
  privacyPolicy: () => '/privacy_policy',
  removeAccount: () => '/remove_account',
  // resetPassword: () => '/reset_password',
  termsAndConditions: () => '/terms_and_conditions',
  updatePassword: () => '/update_password',
  verifyAccount: () => '/verify_account',
};
