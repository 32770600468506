import React from "react";
import { Description, FooterContainer, FooterText, Image, ItemContainer, Title } from "./styles";
import { ArticleProps } from "./types";
import { useNavigate } from "react-router-dom";
import { pageUrls } from "../../../urls";

export const ArticleListItem = ({ title, path, imageUrl, language, updatedAt, estimatedTime, description }: ArticleProps) => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(pageUrls.article(language, path))
  };

  return (
    <ItemContainer onClick={handleOnClick}>
      <Image src={imageUrl} alt={title} />
      <Title>{title}</Title>
      <Description>{description}</Description>
      <FooterContainer>
        <FooterText>{updatedAt?.toLocaleDateString()}</FooterText>
        <FooterText>{estimatedTime}min</FooterText>
      </FooterContainer>
    </ItemContainer>
  );
};
