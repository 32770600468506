import { useContext, useEffect } from "react";
import { ApiDataContext, ApiRequestContext } from "@tour-buddy/ui-api-provider";
import { useParams } from "react-router-dom";
import { Models } from "@tour-buddy/interfaces";
import { ArticleContents, ARTICLES_COMPONENTS_TYPES, TitleComponentType } from "./types";

const mapArticleContent = (
  article: Partial<Models.Article>
): ArticleContents[] => {
  const { content = [], imageUrl } = article || {};

  const contents: ArticleContents[] = [];

  if (imageUrl) {
    contents.push({
      type: ARTICLES_COMPONENTS_TYPES.image,
      value: { imageUrl: article?.imageUrl || "" },
    });
  }

  content.forEach(
    ({ header, text, planId, placeId, imageUrl, plan, place }) => {
      if (header) {
        contents.push({
          type: ARTICLES_COMPONENTS_TYPES.title,
          value: {
            text: header,
            id: header?.toLowerCase().replace(/ /g, "_"),
          },
        });
      }

      if (imageUrl) {
        contents.push({
          type: ARTICLES_COMPONENTS_TYPES.image,
          value: {
            imageUrl: imageUrl || "",
          },
        });
      }

      if (text) {
        contents.push({
          type: ARTICLES_COMPONENTS_TYPES.text,
          value: {
            text,
          },
        });
      }

      if (planId && plan) {
        contents.push({
          type: ARTICLES_COMPONENTS_TYPES.plan,
          value: {
            plan,
          },
        });
      }

      if (placeId && place) {
        contents.push({
          type: ARTICLES_COMPONENTS_TYPES.place,
          value: {
            place,
          },
        });
      }
    }
  );
  return contents;
};

export const useArticle = () => {
  const { article } = useContext(ApiDataContext);
  const { getArticle } = useContext(ApiRequestContext);
  const { language, path } = useParams();

  useEffect(() => {
    if (language && path) {
      getArticle.request({ variables: { articlePath: `${language}/${path}` } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, path]);

  const content = mapArticleContent(article);
  const contentHeaders = content.filter(({ type }) => type === ARTICLES_COMPONENTS_TYPES.title) as TitleComponentType[];

  const tableOfContentProps = contentHeaders?.length ? {
    header: "Spis treści",
    items: contentHeaders.map(({ value }) => ({
      text: value?.text,
      link: value?.id,
    })),
  } : null;

  return {
    title: article?.title || '',
    estimatedReadingTime: article.estimateReadingTime
      ? `${article.estimateReadingTime}`
      : "",
    updatedAt: article?.updatedAt ? (new Date(article?.updatedAt))?.toLocaleDateString() : "",
    content,
    tableOfContentProps,
  };
};
