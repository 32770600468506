export enum Variants {
  contained = 'contained',
  outlined = 'outlined',
  text = 'text',
}

export enum Colors {
  primary = 'primary',
  secondary = 'secondary',
  text = 'text',
  info = 'info',
}

export enum Size {
  tiny = 'tiny',
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export interface ButtonProps {
  variant?: Variants;
  color?: Colors;
  size?: Size;
  text?: string;
  Icon?: React.ReactNode;
  fullWidth?: boolean;
  align?: 'left' | 'center' | 'right';
  onClick: () => void;
}
