import styled from "@emotion/styled";
import { AlertCircleIcon, CheckCircleIcon } from "../../../components/icons";
import { theme } from "../../../theme";
import { Colors } from "../../../types";

export const Container = styled('div')`
  position: absolute;
  width: 100%;
  height: calc(100% - 64px);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const Content = styled('div')`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 16px 8px;
  margin: 8px;

  @media (min-width: 768px) {
    padding: 32px;
  }
`;

export const Header = styled('h1')`
  margin-bottom: 32px;
`;

export const StyledCheckCircleIcon = styled(CheckCircleIcon)`
  width: 64px;
  height: 64px;
  stroke: ${theme.colors[Colors.success].main};
  margin-bottom: 32px;
`;

export const StyledAlertCircleIcon = styled(AlertCircleIcon)`
  margin-bottom: 32px;
  width: 48px;
  height: 48px;
  stroke: ${theme.colors[Colors.error].main};
`;
