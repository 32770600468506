import { ActionProps, DynamicProps } from "./types";
import { pageUrls } from "../../pages/urls";

export const HEADER_ACTIONS = ({ navigate }: DynamicProps): ActionProps[] => ([
  {
    type: 'button',
    text: "Blog",
    onClick: () => navigate(pageUrls.homepage()),
  }
]);

export const TOP_ACTIONS = ({ navigate }: DynamicProps): ActionProps[] => ([]);

export const BOTTOM_ACTIONS = ({ navigate }: DynamicProps): ActionProps[] => ([]);
