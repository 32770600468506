import React from "react";
import { ImageProps } from "./types";
import { ImageContainer, ImageStyled } from "./styles";

export const Image = ({ imageUrl, imageAlt }: ImageProps) => {
  return imageUrl ? (
    <ImageContainer>
      <ImageStyled src={imageUrl} alt={imageAlt} />
    </ImageContainer>
  ) : null;
};
