var _a;
import { QueriesNames } from "./queriesNames";
import { queryMap } from "./queriesMap";
import { queryFieldMap } from "./queriesFields";
export var queryApiPropsMap = (_a = {},
    _a[QueriesNames.sendVerificationUser] = {
        query: queryMap.sendVerificationUser,
        queryField: queryFieldMap.sendVerificationUser,
    },
    _a[QueriesNames.authUser] = {
        query: queryMap.authUser,
        queryField: queryFieldMap.authUser,
    },
    _a[QueriesNames.mapPlaces] = {
        query: queryMap.mapPlaces,
        queryField: queryFieldMap.mapPlaces,
    },
    _a[QueriesNames.searchPlaces] = {
        query: queryMap.searchPlaces,
        queryField: queryFieldMap.searchPlaces,
    },
    _a[QueriesNames.nearbyPlaces] = {
        query: queryMap.nearbyPlaces,
        queryField: queryFieldMap.nearbyPlaces,
    },
    _a[QueriesNames.placesByIds] = {
        query: queryMap.placesByIds,
        queryField: queryFieldMap.placesByIds,
    },
    _a[QueriesNames.popularCityPlaces] = {
        query: queryMap.popularCityPlaces,
        queryField: queryFieldMap.popularCityPlaces,
    },
    _a[QueriesNames.selectedPlace] = {
        query: queryMap.selectedPlace,
        queryField: queryFieldMap.selectedPlace,
    },
    _a[QueriesNames.userPlans] = {
        query: queryMap.userPlans,
        queryField: queryFieldMap.userPlans,
    },
    _a[QueriesNames.selectedUserPlan] = {
        query: queryMap.selectedUserPlan,
        queryField: queryFieldMap.selectedUserPlan,
    },
    _a[QueriesNames.updateUserPlan] = {
        query: queryMap.updateUserPlan,
        queryField: queryFieldMap.updateUserPlan,
    },
    _a[QueriesNames.removeUserPlan] = {
        query: queryMap.removeUserPlan,
        queryField: queryFieldMap.removeUserPlan,
    },
    _a[QueriesNames.updatePlaceUserPlans] = {
        query: queryMap.updatePlaceUserPlans,
        queryField: queryFieldMap.updatePlaceUserPlans,
    },
    _a[QueriesNames.addPlaceImageInit] = {
        query: queryMap.addPlaceImageInit,
        queryField: queryFieldMap.addPlaceImageInit,
    },
    _a[QueriesNames.addFact] = {
        query: queryMap.addFact,
        queryField: queryFieldMap.addFact,
    },
    _a[QueriesNames.addUserPlan] = {
        query: queryMap.addUserPlan,
        queryField: queryFieldMap.addUserPlan,
    },
    _a[QueriesNames.loginUser] = {
        query: queryMap.loginUser,
        queryField: queryFieldMap.loginUser,
    },
    _a[QueriesNames.resetPassword] = {
        query: queryMap.resetPassword,
        queryField: queryFieldMap.resetPassword,
    },
    _a[QueriesNames.forgotPassword] = {
        query: queryMap.forgotPassword,
        queryField: queryFieldMap.forgotPassword,
    },
    _a[QueriesNames.verifyUser] = {
        query: queryMap.verifyUser,
        queryField: queryFieldMap.verifyUser,
    },
    _a[QueriesNames.createUser] = {
        query: queryMap.createUser,
        queryField: queryFieldMap.createUser,
    },
    _a[QueriesNames.updateUser] = {
        query: queryMap.updateUser,
        queryField: queryFieldMap.updateUser,
    },
    _a[QueriesNames.logoutUser] = {
        query: queryMap.logoutUser,
        queryField: queryFieldMap.logoutUser,
    },
    _a[QueriesNames.removeUser] = {
        query: queryMap.removeUser,
        queryField: queryFieldMap.removeUser,
    },
    _a[QueriesNames.updatePassword] = {
        query: queryMap.updatePassword,
        queryField: queryFieldMap.updatePassword,
    },
    _a[QueriesNames.location] = {
        query: queryMap.location,
        queryField: queryFieldMap.location,
    },
    _a[QueriesNames.locationSuggestions] = {
        query: queryMap.locationSuggestions,
        queryField: queryFieldMap.locationSuggestions,
    },
    _a[QueriesNames.subscribeNewsletter] = {
        query: queryMap.subscribeNewsletter,
        queryField: queryFieldMap.subscribeNewsletter,
    },
    _a[QueriesNames.articles] = {
        query: queryMap.articles,
        queryField: queryFieldMap.articles,
    },
    _a[QueriesNames.article] = {
        query: queryMap.article,
        queryField: queryFieldMap.article,
    },
    _a);
