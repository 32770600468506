import React from "react";
import { TitleProps } from "./types";
import { TitleContainer, TitleText } from "./styles";

export const Title = ({ text, id }: TitleProps) => {
  return (
    <TitleContainer id={id}>
      <TitleText>{text}</TitleText>
    </TitleContainer>
  );
};
