import { useApiProviderData } from "./useApiProviderData";
import { useApiProviderRequests } from "./useApiProviderRequests";
import { useApiProviderSuccessLogic } from "./useApiProviderSuccessLogic";
export var useApiProvider = function () {
    var _a = useApiProviderData(), data = _a.data, setMethods = _a.setMethods;
    var requests = useApiProviderRequests(setMethods);
    useApiProviderSuccessLogic(requests);
    return {
        data: data,
        requests: requests,
    };
};
