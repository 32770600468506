import { useEffect } from "react";
export var useOnSuccess = function (_a) {
    var queryPending = _a.queryPending, queryOnSuccess = _a.queryOnSuccess;
    useEffect(function () {
        var _a;
        if (queryPending.isFinished && queryPending.data) {
            var _b = (_a = queryPending.data) !== null && _a !== void 0 ? _a : {}, statusCode = _b.statusCode, data = _b.data;
            if (data && statusCode === 200) {
                queryOnSuccess.response.refetch();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryPending.isFinished, queryPending.data]);
};
