import styled from "@emotion/styled";
import { Colors, ButtonProps } from "./types";
import { theme } from "../../theme";


const Container = styled('div')<{ color: Colors; fontSize: number; padding: string; fullWidth: boolean; align?: ButtonProps['align'] }>`
    cursor: pointer;
    padding: ${({ padding }) => padding};
    display: inline-flex;
    flex-flow: row;
    border-radius: ${theme.borderRadius.button}px;
    font-size: ${({ fontSize }) => fontSize}px;
    font-weight: ${theme.font.fontWeights.semiBold};
    transition: background-color 100ms ease-in, border 100ms ease-in, color 100ms ease-in;
    width: ${({ fullWidth }) => fullWidth ? '100%' : 'auto'};
    justify-content: ${({ align }) => {
      if (align === 'right') return 'flex-end';
      else if (align === 'left') return 'flex-start';
      return 'center';
    }};
`;

export const ContainerOutlined = styled(Container)`
    border: 2px solid ${({ color }) => theme.colors[color].main};
    background-color: white;
    color: ${({ color }) => theme.colors[color].main};

    & > svg {
        transition: stroke 100ms ease-in;
    }
    
    &:hover {
        color: ${({ color }) => theme.colors[color].dark};
        border: 2px solid ${({ color }) => theme.colors[color].dark};
      
        & > svg {
            stroke: ${({ color }) => theme.colors[color].dark};
        }
  }
`;

export const ContainerContained = styled(Container)`
    background-color: ${({ color }) => theme.colors[color].main};
    color: ${({ color }) => theme.colors[color].contrast};
    
    &:hover {
        background-color: ${({ color }) => theme.colors[color].dark};
    }
`;

export const ContainerText = styled(Container)`
    background-color: white;
    color: ${({ color }) => theme.colors[color].main};

    & > svg {
        transition: stroke 100ms ease-in;
    }

    &:hover {
        color: ${({ color }) => theme.colors[color].dark};
        & > svg {
            stroke: ${({ color }) => theme.colors[color].dark};
        }
    }
`;
