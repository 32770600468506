import React, { ReactNode } from "react";
import { BrowserRouter } from 'react-router-dom';
import { TranslationLanguage, TranslationsProvider } from "@tour-buddy/ui-translations";
import { SessionProvider } from './components/sessionProvider';
import { Pages } from './pages';
import { SnackbarProvider } from 'notistack';
import { ApiProvider } from "@tour-buddy/ui-api-provider";
import { getLocalStorageItem, LOCAL_STORAGE_KEYS } from "./utils/localStorage";
import { AppRoot } from "./styles";
import { DrawerProvider } from "./components/layout";

const ContextsProvider = ({ children }: { children: ReactNode }) => (
  <SessionProvider>
    <DrawerProvider>
      <ApiProvider>
        {children}
      </ApiProvider>
    </DrawerProvider>
  </SessionProvider>
);

const TranslatedApp = () => {
  const language = getLocalStorageItem(LOCAL_STORAGE_KEYS.LANGUAGE);

  return (
    <TranslationsProvider
      language={
        (language as TranslationLanguage) || TranslationLanguage.pl
      }
    >
      <Pages />
    </TranslationsProvider>
  );
};

const App = () => (
  <AppRoot>
    <BrowserRouter>
      <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }} dense>
        <ContextsProvider>
          <TranslatedApp />
        </ContextsProvider>
      </SnackbarProvider>
    </BrowserRouter>
  </AppRoot>
);

export default App;
