import React from "react";
import { Container, Content } from "./styles";
import { useBlogHomepage } from "./useBlogHomepage";
import { ArticleList } from "./articleList";

export const BlogHomepage = () => {
  const { articles } = useBlogHomepage();
  return (
    <Container>
      <Content>
        <ArticleList articles={articles} />
      </Content>
    </Container>
  );
};
