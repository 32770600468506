import React from "react";
import {
  ArticleContentContainer,
  SubTitleContainer,
  BelowTitleText,
  Container,
  Content,
  Title,
  BelowTitleContainer
} from "./styles";
import { useArticle } from "./useArticle";
import { getArticleComponentByType } from "./articleComponents";
import { TableOfContent } from "./tableOfContent";

export const Article = () => {
  const { title, content, updatedAt, estimatedReadingTime, tableOfContentProps } = useArticle();

  return (
    <Container>
      <Content>
        <Title>{title}</Title>
        <SubTitleContainer>
          <BelowTitleText>{updatedAt}</BelowTitleText>
          {estimatedReadingTime && <BelowTitleText>{estimatedReadingTime}min</BelowTitleText>}
        </SubTitleContainer>
        <BelowTitleContainer>
          <ArticleContentContainer>
            {content.map(({ type, value }, index) => {
              const ContentComponent = getArticleComponentByType(type);
              if (!ContentComponent) return null;
              return <ContentComponent key={index} {...value} />;
            })}
          </ArticleContentContainer>
          {tableOfContentProps && <TableOfContent {...tableOfContentProps} />}
        </BelowTitleContainer>
      </Content>
    </Container>
  );
};
