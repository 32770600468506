import React from "react";
import { ButtonProps, Colors, Size, Variants } from "./types";
import {
  ContainerOutlined,
  ContainerText,
  ContainerContained,
} from "./styles";
import { theme } from "../../theme";

const containerMap: Record<Variants, React.FC<any>> = {
  [Variants.contained]: ContainerContained,
  [Variants.outlined]: ContainerOutlined,
  [Variants.text]: ContainerText,
};

const fontSizeMap: Record<Size, number> = {
  [Size.tiny]: theme.font.sizes.body2,
  [Size.small]: theme.font.sizes.body1,
  [Size.medium]: theme.font.sizes.h3,
  [Size.large]: theme.font.sizes.h2,
};

const paddingMap: Record<Size, string> = {
  [Size.tiny]: '2px 4px',
  [Size.small]: '4px 8px',
  [Size.medium]: '8px 16px',
  [Size.large]: '16px 32px',
};

export const Button: React.FC<ButtonProps> = ({ text, align = 'center', variant = Variants.contained, color = Colors.primary, size = Size.medium, onClick, Icon, fullWidth = false }) => {
  const Container = containerMap[variant] || ContainerContained;
  const fontSize = fontSizeMap[size] || fontSizeMap[Size.medium];
  const padding = paddingMap[size] || paddingMap[Size.medium];

  return (
    <Container color={color} fontSize={fontSize} padding={padding} onClick={onClick} fullWidth={fullWidth} align={align}>
      {Icon ? Icon : null}
      {text && <span>{text}</span>}
    </Container>
  );
};
