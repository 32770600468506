import styled from "@emotion/styled";
import { NavigationMenuIcon } from "../../icons";
import { theme } from "../../../theme";
import { Colors } from "../../../types";
import { Logo } from "../../logo";

export const Container = styled('div')`
    height: 48px;
    width: calc(100% - 8px);
    padding: 4px;
    display: flex;
    flex-flow: row;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    box-shadow: 0px -4px 12px 0px rgba(66, 68, 90, 0.5);
    
    ${theme.mediaQueries.desktop} {
        padding: 4px 16px;
        width: calc(100% - 32px);
    }
`;

export const LogoContainer = styled('div')`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const LogoStyled = styled(Logo)`
    height: 42px;
    width: auto;
`;

export const RightContent = styled('div')`
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    gap: 8px;
`;

export const DesktopOnlyContent = styled('div')`
    gap: 8px;
    display: none;

    ${theme.mediaQueries.desktop} {
        display: flex;
    }
`;

export const StyledNavigationMenuIcon = styled(NavigationMenuIcon)`
  width: 32px;
  height: 32px;
  stroke: ${theme.colors[Colors.primary].main};
`;
