import React from 'react';
import { IconProps } from './types';

export const CheckCircleIcon = ({ className, style }: IconProps) => (
  <svg style={style} className={className} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" strokeWidth="1.5">
    <path
      d="M6,13.223,8.45,16.7a1.049,1.049,0,0,0,1.707.051L18,6.828"
      fill="none"
      stroke="inherit"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
    <path
      d="M0.750 11.999 A11.250 11.250 0 1 0 23.250 11.999 A11.250 11.250 0 1 0 0.750 11.999 Z"
      fill="none"
      stroke="inherit"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
  </svg>
);
