import { useState } from "react";
import { initialDataValues, } from "../constants/initialDataValues";
export var useApiProviderData = function () {
    var _a = useState(initialDataValues.nearbyPlaces), nearbyPlaces = _a[0], setNearbyPlaces = _a[1];
    var _b = useState(initialDataValues.nearbyPlaces), searchPlaces = _b[0], setSearchPlaces = _b[1];
    var _c = useState(initialDataValues.placesList), placesList = _c[0], setPlacesList = _c[1];
    var _d = useState(initialDataValues.popularCityPlaces), popularCityPlaces = _d[0], setPopularCityPlaces = _d[1];
    var _e = useState(initialDataValues.mapPlaces), mapPlaces = _e[0], setMapPlaces = _e[1];
    var _f = useState(initialDataValues.selectedPlace), selectedPlace = _f[0], setSelectedPlace = _f[1];
    var _g = useState(initialDataValues.userPlans), userPlans = _g[0], setUserPlans = _g[1];
    var _h = useState(initialDataValues.selectedUserPlan), selectedUserPlan = _h[0], setSelectedUserPlan = _h[1];
    var _j = useState(initialDataValues.locationSuggestions), locationSuggestions = _j[0], setLocationSuggestions = _j[1];
    var _k = useState(initialDataValues.userLocation), userLocation = _k[0], setUserLocation = _k[1];
    var _l = useState(initialDataValues.articles), articles = _l[0], setArticles = _l[1];
    var _m = useState(initialDataValues.article), article = _m[0], setArticle = _m[1];
    var setMethods = {
        setSelectedUserPlan: setSelectedUserPlan,
        setSelectedPlace: setSelectedPlace,
        setUserPlans: setUserPlans,
        setMapPlaces: setMapPlaces,
        setNearbyPlaces: setNearbyPlaces,
        setLocationSuggestions: setLocationSuggestions,
        setUserLocation: setUserLocation,
        setPopularCityPlaces: setPopularCityPlaces,
        setSearchPlaces: setSearchPlaces,
        setPlacesList: setPlacesList,
        setArticle: setArticle,
        setArticles: setArticles,
    };
    var data = {
        selectedUserPlan: selectedUserPlan,
        selectedPlace: selectedPlace,
        userPlans: userPlans,
        mapPlaces: mapPlaces,
        nearbyPlaces: nearbyPlaces,
        popularCityPlaces: popularCityPlaces,
        locationSuggestions: locationSuggestions,
        userLocation: userLocation,
        searchPlaces: searchPlaces,
        placesList: placesList,
        articles: articles,
        article: article,
    };
    return {
        data: data,
        setMethods: setMethods,
    };
};
