var translations = {
    "main.landingPage.newsletter.header": "Be up to date",
    "main.landingPage.newsletter.h2": "Get updated when we reach new land",
    "main.landingPage.newsletter.input": "Provide your email",
    "main.landingPage.newsletter.inputLabel": "E-mail",
    "main.landingPage.newsletter.button": "Subscribe",
    "main.landingPage.features.header": "Features",
    "main.landingPage.features.feature1Header": "Personalized Guides",
    "main.landingPage.features.feature1Description": "With Wizytor, every trip is tailored to your preferences. Whether you're short on time or revisiting a familiar city, our app crafts custom guides based on your location, available time, and more. Enjoy a curated experience that matches your interests effortlessly.",
    "main.landingPage.features.feature2Header": "Everything in One Place",
    "main.landingPage.features.feature2Description": "Tired of scouring through endless blog posts for travel information? Wizytor consolidates everything you need in one convenient place. From detailed insights to insider tips, our app ensures that you have comprehensive access to all the information you need for a memorable journey.",
    "main.landingPage.features.feature3Header": "Effortless Trip Plans",
    "main.landingPage.features.feature3Description": "Say goodbye to last-minute planning hassles with Wizytor. Prepare for your next trip in advance and save valuable time on-site. Our app allows you to create and save trip plans seamlessly, ensuring a stress-free travel experience from start to finish",
    "main.landingPage.features.feature4Header": "No Opinions, Only Facts",
    "main.landingPage.features.feature4Description": "At Wizytor, we prioritize accuracy and reliability. We rely solely on verified facts from recognized sources. Rest assured that the information you receive is based on authenticity, not subjective opinions or reviews.",
    "main.landingPage.numbers.header": "Wizytor in numbers",
    "main.landingPage.numbers.paragraph": "we are growing everyday",
    "main.landingPage.numbers.cityNumber": "500+",
    "main.landingPage.numbers.cityTitle": "cities",
    "main.landingPage.numbers.countryNumber": "1",
    "main.landingPage.numbers.countryTitle": "country",
    "main.landingPage.numbers.placesNumber": "55k+",
    "main.landingPage.numbers.placesTitle": "places",
    "main.landingPage.main.header": "Personalized Tour Guide In Your Pocket",
    "main.landingPage.main.description": "Discover the world around you like never before with Wizytor. Say goodbye to generic tour guides and be prepared for a personalized exploration experience tailored just for you.",
    "main.forgotPassword.title": "Forgot password",
    "main.forgotPassword.successfulMessage": "Message sent to your email",
    "main.forgotPassword.loginRedirectButton": "Login page",
    "main.forgotPassword.loginRedirectText": "Go back to Login page",
    "main.forgotPassword.submit": "Send",
    "main.forgotPassword.loader": "Sending email...",
    "main.resetPassword.title": "Reset password",
    "main.resetPassword.submit": "Reset",
    "main.resetPassword.loaderUpdatingPassword": "Updating password...",
    "main.registerAccount.successfulMessage": "Account created.\nCheck your email to verify account. You can login to the application after successful email verification",
    "main.registerAccount.title": "Register new account",
    "main.registerAccount.submit": "Create account",
    "main.registerAccount.loaderCreatingUser": "Creating user...",
    "main.registerAccount.loginRedirectText": "Already have account?",
    "main.registerAccount.loginRedirectButton": "Sign in",
    "main.registerAccount.passwordRequirements": "Password must have: \n8 characters \n1 big letter \n1 number \n1 special character",
    "main.registerAccount.confirmPolicyTermsAndCondition": "Agree to Terms and Condition and Privacy Policy",
    "main.login.title": "Login",
    "main.login.submit": "Login",
    "main.login.registerRedirectText": "Don't have account yet?",
    "main.login.registerRedirectButton": "Create account",
    "main.login.registerForgotPassword": "Forgot password",
    "main.verifyAccount.title": "Verify account",
    "main.verifyAccount.waiting": "Checking, please wait...",
    "main.verifyAccount.error": "Something went wrong during verification of your account",
    "main.verifyAccount.success": "Your account was successfully verified",
    "main.verifyAccountModal.title": "Verify account",
    "main.verifyAccountModal.message": "Send verification message to your email",
    "main.verifyAccountModal.sendVerificationButton": "Send email",
    "main.verifyAccountModal.backButton": "Back",
    "main.userProfile.title": "User profile",
    "main.userPlans.header": "{{plansNum}} plans",
    "main.userPlans.availableTime": "{{hours}}h {{minutes}}min",
    "main.userPlans.availableDays": "{{days}} days",
    "main.userPlans.placesNumber": "{{placesNum}} places",
    "main.userPlans.removeAccountConfirmHeader": "Remove account",
    "main.places.header": "{{placesNum}} places",
    "main.places.showAll": "Show all",
    "main.plan.loadingPlan": "Loading plan...",
    "main.plan.calculatingRoutes": "Calculating routes...",
    "main.plan.loadingPlace": "Loading place...",
    "main.plan.placesListHeader": "Day {{day}}",
    "main.main.nearbyPlacesHeader": "Nearby places",
    "main.main.popularPlacesHeader": "Popular places in {{city}}",
    "main.placeDetails.scoreOverallHeader": "Overall score",
    "main.placeDetails.scoreOverallDescription": "",
    "main.placeDetails.dataHeader": "Data score",
    "main.placeDetails.dataDescription": "",
    "main.placeDetails.childrenPlacesHeader": "You can also find here:",
    "main.placeDetails.parentPlacesHeader": "This place is part of:",
    "main.placeDetails.factsHeader": "{{factsNum}} facts",
    "main.articles.estimatedReadingTime": "{{estimatedReadingTime}} minutes reading time",
    "main.articles.placeRedirect": "See details",
    "main.articles.planRedirect": "See details",
    "main.input.label.email": "Email",
    "main.input.label.password": "Password",
    "main.input.label.confirmPassword": "Confirm password",
    "main.input.label.firstName": "First name",
    "main.input.label.lastName": "Last name",
    "main.input.label.availableTime": "Available time",
    "main.input.label.availableDays": "Number of Days",
    "main.input.label.availableTimePerDay": "Day {{day}}",
    "main.input.label.availableTimeSpecificSwitch": "Specific time per day",
    "main.input.label.startPlace": "Start from",
    "main.input.label.finishPlace": "Finish at",
    "main.input.label.sameStartFinishPlace": "Same start-finish point",
    "main.input.label.specifiedPlacePerDay": "Specified places per day",
    "main.input.label.oldPassword": "Old Password",
    "main.input.label.newPassword": "New Password",
    "main.input.label.search": "Find places",
    "main.button.showMore": "Show more",
    "main.button.logout": "Logout",
    "main.button.changePassword": "Change Password",
    "main.button.removeAccount": "Remove Account",
    "main.button.goBackHome": "Go back to home page",
    "main.button.goBackPlaceList": "Go back to place list",
    "main.button.goBackUserPlanList": "Go back to plans list",
    "main.button.goBackPlan": "Go back to plan",
    "main.button.renamePlan": "Rename",
    "main.button.removePlan": "Remove",
    "main.button.readMore": "Read more",
    "main.button.readLess": "Read less",
    "main.button.showOnMap": "Show on map",
    "main.button.refresh": "Refresh",
    "main.button.planCreate": "Search",
    "main.button.showAdvancedFilters": "Advanced filters",
    "main.button.planEdit": "Update",
    "main.button.verifyAccount": "Verify Account",
    "main.button.removeAccountCancel": "Do not remove",
    "main.button.removeAccountConfirm": "Remove",
    "main.button.showAll": "See all",
    "main.button.back": "Back",
    "main.button.next": "Next",
    "main.tag.tourism": "tourism",
    "main.tag.architecture": "architecture",
    "main.tag.park": "park",
    "main.tag.historic": "historic",
    "main.tag.facility": "facility",
    "main.tag.education": "education",
    "main.tag.commercial": "commercial",
    "main.tag.industrial": "industrial",
    "main.tag.neighborhood": "neighborhood",
    "main.tag.city": "city",
    "main.tag.natural": "natural",
    "main.tag.square": "square",
    "main.tag.street": "street",
    "main.tag.station": "station",
    "main.tag.cinema": "cinema",
    "main.tag.theatre": "theatre",
    "main.tag.library": "library",
    "main.tag.bridge": "bridge",
    "main.error.userExists": "user already exists",
    "main.error.emailFormat": "invalid email format",
    "main.error.passwordRequirements": "password doesn't match requirements",
    "main.error.passwordNotMatch": "password not match",
    "main.error.missingEmail": "missing email",
    "main.error.missingFirstName": "missing first name",
    "main.error.missingLastName": "missing last name",
    "main.error.missingPassword": "missing password",
    "main.error.cannotLogin": "cannot login user, try again",
    "main.error.cannotCreateUser": "cannot create user, try again",
    "main.error.cannotUpdatePassword": "cannot update password, try again",
    "main.drawer.button.main": "Home",
    "main.drawer.button.map": "Map",
    "main.drawer.button.userPlans": "Your plans",
    "main.drawer.button.userProfile": "User profile",
    "main.drawer.button.articles": "Articles",
    "main.drawer.language.header": "Select language",
    "main.button.close": "close",
};
export default translations;
