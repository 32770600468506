import { ChangeEventHandler, FocusEventHandler, useEffect, useState } from 'react';

type Validation = {
  message: string;
  pattern?: string;
  minLength?: number;
  maxLength?: number;
  check?: (value: string) => boolean;
};

interface ValidateProps {
  validation: Validation[];
  value: string;
  required: boolean;
}

export interface UseInputProps {
  defaultValue?: string;
  type?: string;
  validation?: Validation[];
  required?: boolean;
}

export interface UseInputResponse {
  onChange: ChangeEventHandler<HTMLInputElement>;
  onBlur: FocusEventHandler<HTMLInputElement>;
  value: string;
  error: boolean;
  type: string;
  helperText: string;
  required: boolean;
}

const validate = ({ validation, value, required }: ValidateProps) => {
  let error = false;
  let errorMessage = '';
  validation.forEach(({ message, pattern, minLength, maxLength, check }) => {
    if (
      (minLength && value.length < minLength) ||
      (maxLength && value.length > maxLength) ||
      (pattern && !new RegExp(pattern).test(value)) ||
      (check && check(value))
    ) {
      error = true;
      errorMessage = message;
    }
  });
  if (required && !value?.length) {
    error = true;
    errorMessage = 'Required input';
  }

  return { error, errorMessage };
};

export const useInputString = (props: UseInputProps = {}): UseInputResponse => {
  const { defaultValue = null, type = 'text', validation = [], required = false } = props;

  const [value, setValue] = useState<string | null>(defaultValue);
  const [isError, setIsError] = useState<boolean>(false);
  const [helperText, setHelperText] = useState<string>('');

  useEffect(() => {
    if (value !== null) {
      const { errorMessage, error } = validate({ validation, value, required });
      setIsError(error);
      setHelperText(errorMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
  };

  const handleOnBlur: FocusEventHandler<HTMLInputElement> = () => {
    if (required) {
      setValue((prev) => prev ?? '');
    } else {
      if (value === '') {
        setIsError(false);
        setHelperText('');
      }
    }
  };

  return {
    onChange: handleOnChange,
    onBlur: handleOnBlur,
    value: value ?? '',
    error: isError,
    type,
    helperText,
    required,
  };
};
