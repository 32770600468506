import React from "react";
import { HeaderProps } from "./types";
import { Button, Colors, Variants } from "../../button";
import {
  Container,
  DesktopOnlyContent,
  LogoContainer,
  LogoStyled,
  RightContent,
  StyledNavigationMenuIcon
} from "./styles";

export const Header: React.FC<HeaderProps> = ({ desktopOnlyActions = [], onNavigationClick, onLogoClick, showDrawerIcon = false }) => {
  return (
    <Container>
      <LogoContainer onClick={onLogoClick}>
        <LogoStyled />
      </LogoContainer>
      <RightContent>
        <DesktopOnlyContent>
          {desktopOnlyActions.map(({ type, text, onClick }) => type === 'button' && (
            <Button text={text} color={Colors.text} variant={Variants.text} onClick={onClick as VoidFunction} />
          ))}
        </DesktopOnlyContent>
        {showDrawerIcon && (
          <Button onClick={onNavigationClick} Icon={<StyledNavigationMenuIcon />} variant={Variants.text} />
        )}
      </RightContent>
    </Container>
  );
};
