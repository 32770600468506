import React from 'react';
import { IconProps } from './types';

export const NavigationMenuIcon = ({ className, style }: IconProps) => (
  <svg style={style} className={className} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" strokeWidth="1.5">
    <path
      d="M2.25 18.003L21.75 18.003"
      fill="none"
      stroke="inherit"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
    <path
      d="M2.25 12.003L21.75 12.003"
      fill="none"
      stroke="inherit"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
    <path d="M2.25 6.003L21.75 6.003" fill="none" stroke="inherit" strokeLinecap="round" strokeLinejoin="round"></path>
  </svg>
);
