export enum PLACE_TAGS {
  tourism = "tourism",
  architecture = "architecture",
  park = "park",
  historic = "historic",
  facility = "facility",
  education = "education",
  commercial = "commercial",
  industrial = "industrial",
  neighborhood = "neighborhood",
  city = "city",
  natural = "natural",
  square = "square",
  street = "street",
  station = "station",
  cinema = "cinema",
  theatre = "theatre",
  library = "library",
  bridge = "bridge",
}
