import {
  ImageProps,
  ParagraphProps, PlaceProps, PlanProps,
  TitleProps
} from "@tour-buddy/main-mobile/src/pages/article/articleComponents/types";

export enum ARTICLES_COMPONENTS_TYPES {
  text = "text",
  image = "image",
  place = "place",
  plan = "plan",
  title = "title",
}

interface ArticleContent<
  Type extends ARTICLES_COMPONENTS_TYPES,
  Value = string
> {
  type: Type;
  value: Value;
}

export type TextComponentType = ArticleContent<
  ARTICLES_COMPONENTS_TYPES.text,
  ParagraphProps
>;
export type TitleComponentType = ArticleContent<
  ARTICLES_COMPONENTS_TYPES.title,
  TitleProps
>;
export type ImageComponentType = ArticleContent<
  ARTICLES_COMPONENTS_TYPES.image,
  ImageProps
>;
export type PlaceComponentType = ArticleContent<
  ARTICLES_COMPONENTS_TYPES.place,
  PlaceProps
>;
export type PlanComponentType = ArticleContent<
  ARTICLES_COMPONENTS_TYPES.plan,
  PlanProps
>;

export type ArticleContents =
  | TextComponentType
  | TitleComponentType
  | ImageComponentType
  | PlaceComponentType
  | PlanComponentType;
