import styled from '@emotion/styled';

const HEADER_HEIGHT = 56;
const FOOTER_HEIGHT = 56;

export const Container = styled('div')`
    display: flex;
`;

export const ContentWithFooter = styled('div')`
    width: 100%;
`;

export const Content = styled('div')`
    margin-top: 56px;
    min-height: calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px);
`;

